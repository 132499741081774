import * as React from 'react';
import { Link } from 'gatsby';
import { gql } from '@urql/core';
import { captureException, withCounter } from 'utils';
import { CatalogLayout } from 'components/CatalogLayout';
import { PageError } from 'components/PageError';
import { BackButton } from 'components/BackButton';
import { AuthRequired } from 'components/AuthRequired';
import { ProductCardList, ProductCardListSkeleton } from 'components/ProductCardList';
import CartEmptyIcon from 'components/icons/CartEmpty';
import { useMyBoughtPageQuery } from 'generated/graphql';
import { useStoreon } from 'hooks';

const ContentWrapper: React.FC<{ boughtCount?: number }> = ({ children, boughtCount }) => (
  <CatalogLayout>
    <BackButton className="text-2xl font-bold mt-7 mb-11">
      {boughtCount ? withCounter('Купленные товары', boughtCount) : 'Купленные товары'}
    </BackButton>
    {children}
  </CatalogLayout>
);

const Bought = () => {
  const { token } = useStoreon('token');
  const [queryRes, refetchQuery] = useMyBoughtPageQuery({ requestPolicy: 'cache-and-network' });

  if (queryRes.error) {
    const retry = () => refetchQuery({ requestPolicy: 'network-only' });
    return (
      <ContentWrapper>
        <PageError error={queryRes.error} retry={retry} className="justify-center" />
      </ContentWrapper>
    );
  }

  const noUser = queryRes.data && !queryRes.data.viewer.user;
  if ((queryRes.fetching && !queryRes.data) || (noUser && token)) {
    return (
      <ContentWrapper>
        <ProductCardListSkeleton />
      </ContentWrapper>
    );
  }

  if (!queryRes.data) {
    captureException(new Error(`my bought page data is null`));
    return null;
  }

  if (!queryRes.data.viewer.user) {
    return (
      <ContentWrapper>
        <AuthRequired />
      </ContentWrapper>
    );
  }

  const { boughtProducts } = queryRes.data.viewer.user;

  return (
    <ContentWrapper boughtCount={boughtProducts.length}>
      {boughtProducts.length > 0 ? (
        <ProductCardList products={boughtProducts} />
      ) : (
        <div className="flex flex-col flex-grow justify-center items-center text-center">
          <CartEmptyIcon size={161} className="mb-5" />
          <h2 className="font-medium text-2xl">Здесь будут храниться ваши купленные товары.</h2>
          <p className="text-lg">Чтобы добавить объект в купленные, закажите что-нибудь.</p>
          <Link
            to="/catalog"
            className="flex items-center h-10 font-medium text-orange-200 bg-orange-100 rounded-md uppercase tracking-wider px-8 mt-5 transition-opacity duration-300 ease-in-out hover:opacity-70"
          >
            В каталог
          </Link>
        </div>
      )}
    </ContentWrapper>
  );
};

export default Bought;

gql`
  query MyBoughtPageQuery {
    viewer {
      id
      user {
        id
        boughtProducts {
          id
          ...ProductCardList_data
        }
      }
    }
  }
`;
